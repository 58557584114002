@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&family=Inter:wght@600&display=swap');

* {
  box-sizing: border-box;
}

h4 {
  color: #424242;
}

html {
  /* background-color: #f2f2f2; */
}

#webpack-dev-server-client-overlay {
  display: none;
}
